<template>
  <div class="stars-component">
    <label class="stars-component-title">{{ label }}</label>
    <form class="rating" @change="qualifi()">
      <label>
        <input type="radio" name="stars" value="1" :checked="isChecked(1)" />
        <span class="icon">★</span>
      </label>
      <label>
        <input type="radio" name="stars" value="2" :checked="isChecked(2)" />
        <span class="icon">★</span>
        <span class="icon">★</span>
      </label>
      <label>
        <input type="radio" name="stars" value="3" :checked="isChecked(3)" />
        <span class="icon">★</span>
        <span class="icon">★</span>
        <span class="icon">★</span>
      </label>
      <label>
        <input type="radio" name="stars" value="4" :checked="isChecked(4)" />
        <span class="icon">★</span>
        <span class="icon">★</span>
        <span class="icon">★</span>
        <span class="icon">★</span>
      </label>
      <label>
        <input type="radio" name="stars" value="5" :checked="isChecked(5)" />
        <span class="icon">★</span>
        <span class="icon">★</span>
        <span class="icon">★</span>
        <span class="icon">★</span>
        <span class="icon">★</span>
      </label>
    </form>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      myrate: null,
      dato: null,
      value: 0,
    };
  },
  props: {
    qualification: 0,
    label: {
      type: String,
      default: "Calificación",
    },
  },
  methods: {
    qualifi(e) {
      const value = event.srcElement.value;
      this.dato = value;
      this.value = value;
      this.$emit("qualifi", value);
    },
    isChecked(value) {
      this.value = parseInt(this.qualification);
      if (parseInt(this.qualification) === value) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.stars-component {
  @include Column(flex-start, flex-start);
  margin-bottom: $mpadding/2;
  &-title {
    color: $chicago;
    font-weight: bold;
  }
}
.rating {
  display: inline-block;
  position: relative;
  height: 40px;
  line-height: 40px;
  font-size: 40px;
}

.rating label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}

.rating label:last-child {
  position: static;
}

.rating label:nth-child(1) {
  z-index: 5;
}

.rating label:nth-child(2) {
  z-index: 4;
}

.rating label:nth-child(3) {
  z-index: 3;
}

.rating label:nth-child(4) {
  z-index: 2;
}

.rating label:nth-child(5) {
  z-index: 1;
}

.rating label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.rating label .icon {
  float: left;
  color: transparent;
}

.rating label:last-child .icon {
  color: #ccc;
}

.rating:not(:hover) label input:checked ~ .icon,
.rating:hover label:hover input ~ .icon {
  color: $lipstick;
}

.rating label input:focus:not(:checked) ~ .icon:last-child {
  color: #ccc;
  text-shadow: 0 0 5px $lipstick;
}
</style>
