<template>
  <div class="data-list">
    <input class="data-list__input" type="text" list="data-list" disabled v-model="newValue" />
    <datalist class="data-list__show" id="data-list" ref="select">
      <option v-for="(option, id) in options" :key="id" :value="option.value"></option>
    </datalist>
  </div>
</template>
<script>
export default {
  props: ["options"],
  data: function() {
    return { newValue: "" };
  },
  watch: {
    newValue: function() {
      this.$emit("textFull", this.newValue);
    }
  },
  methods: {
    onChange: function() {
      this.$emit("selected", this.$refs.select.value);
    },
    reboot: function() {
      this.newValue = "";
    }
  }
};
</script>
<style lang="scss">
.data-list {
  &__input {
    @include Input();
    width: 100%;
  }
  &__show {
    width: 100%;
  }
}
</style>
