<template>
  <div class="input-new">
    <label class="input-new__title">Nueva</label>
    <input
      class="input-new__input"
      type="text"
      :placeholder="placeholder"
      v-model="newValue"
      @keypress.enter.prevent="onSubmit"
      @keydown.esc="onCancel"
      ref="input"
    />
    <slot></slot>
    <div class="input-new__roomIp" v-if="ip">
      <label class="input-new__title">IP</label>
      <input
        class="input-new__input"
        type="text"
        :placeholder="ip['placeholder']"
        v-model="ipValue"
        @keypress.enter.prevent="onSubmit"
        @keydown.esc="onCancel"
        ref="input"
      />
    </div>
    <StarsRatingsInput class="input-new__stars" v-if="isQualification" :qualification="qualificationForm" @qualifi="onQualifi" />
    <div class="input-new__buttons">
      <button class="input-new__button" :disabled="isDisabled" @click.prevent="onSubmit" ref="submit">
        {{ submit }}
      </button>
      <button class="input-new__button input-new__button--cancel" @click.prevent="onCancel">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
import StarsRatingsInput from "@/components/input/StarsRatingsInput.vue";

export default {
  props: ["placeholder", "valueInput", "qualification", "submit", "isQualification", "ip"],
  components: { StarsRatingsInput },
  data: function() {
    return {
      newValue: "",
      qualificationForm: 0,
      ipValue: "",
    };
  },
  computed: {
    isDisabled: function() {
      if (this.isQualification && this.ip?.placeholder) {
        return !this.newValue || this.qualificationForm === 0 || !this.ipValue;
      }
      return !this.newValue;
    },
  },
  methods: {
    onSubmit: function() {
      if (this.isDisabled) return;
      let submitValues = [this.newValue];

      if (this.isQualification && this.ip?.placeholder) {
        submitValues.push(this.qualificationForm, this.ipValue);
      }
      this.$emit("onSubmit", ...submitValues);
    },
    onCancel: function() {
      this.$emit("onCancel");
    },
    onQualifi: function(value) {
      this.qualificationForm = value;
    },
  },
  mounted: function() {
    this.$refs.input.focus();
    this.newValue = this.valueInput || "";
    this.ipValue = this.ip?.value || "";
    this.qualificationForm = this.qualification || 0;
  },
};
</script>

<style lang="scss">
.input-new {
  width: 100%;
  &__title {
    margin-top: 5px;
    padding: 5px;
    font-weight: bold;
    font-size: 14px;
    color: $gray-emperor;
  }
  &__input {
    width: 100%;
    height: 33px;
    margin: auto;
    @include Input();
    &[placeholder] {
      color: $chicago;
    }
  }
  &__stars {
    margin-top: 5px;
    .stars-component-title {
      font-size: 14px;
    }
  }
  &__buttons {
    @include Row();
    margin: $mpadding 0;
  }
  &__button {
    @include ButtonStyle();
    margin: 0;
    margin-right: $mpadding;
    width: 120px;
    background-color: $primary-color;
    &[disabled] {
      background-color: $gray-emperor;
    }
    &--cancel {
      margin-right: 0;
      background-color: $gray-emperor;
    }
  }
}
</style>
