<template>
  <section class="location-form">
    <form class="location-form__form">
      <div class="location-form__input-container">
        <label class="location-form__label">Ciudad</label>
        <div class="location-form__city">
          <div class="location-form__input-subcontainer">
            <select-input class="location-form__select" :options="getCityOptions" @selected="citySelected" ref="select_city"></select-input>
            <button class="location-form__button-add" ref="new_city" @click.prevent="onNewCity">
              <i class="fas fa-plus"></i>
            </button>
            <button class="location-form__button-edit" disabled ref="edit_city" @click.prevent="onEditCity">
              <i class="fas fa-pencil-alt" />
            </button>
          </div>
        </div>
        <div v-if="!!city" :key="resetCity">
          <ToggleInput class="location-form__toogle-city" :checked="toogleCity" :label="toogleCity ? 'Habilitada' : 'Deshabilitada'" @isChecked="onIsCheckedCity" />
          <ToggleInput class="location-form__toogle-city" :checked="toogleCityAvailable" :label="toogleCityAvailable ? 'En operacion' : 'Retirada'" @isChecked="onIsCheckedAvailabilityCity" />
        </div>
        <input-new
          v-if="formCity"
          class="location-form__new"
          :valueInput="isEditCity ? city.cityName : ''"
          :submit="isEditCity ? 'Actualizar' : 'Crear'"
          placeholder="Ciudad"
          @onSubmit="onSubmitCity"
          @onCancel="onCancelNewCity"
        ></input-new>

        <label class="location-form__label">Sede</label>
        <div class="location-form__office">
          <div class="location-form__input-subcontainer">
            <select-input class="location-form__select" :options="getOfficeOptions" @selected="officeSelected" ref="select_office"></select-input>
            <button class="location-form__button-add" disabled @click.prevent="onNewOffice" ref="new_office">
              <i class="fas fa-plus"></i>
            </button>
            <button class="location-form__button-edit" disabled ref="edit_office" @click.prevent="onEditOffice">
              <i class="fas fa-pencil-alt" />
            </button>
          </div>
        </div>
        <div v-if="!!office" :key="resetOffice">
          <ToggleInput class="location-form__toogle-office" :checked="toogleOffice" :label="toogleOffice ? 'Habilitada' : 'Deshabilitada'" @isChecked="onIsCheckedOffice" />
          <ToggleInput class="location-form__toogle-city" :checked="toogleOfficeAvailable" :label="toogleOfficeAvailable ? 'En operacion' : 'Retirada'" @isChecked="onIsCheckedAvailabilityOffice" />
        </div>
        <input-new
          v-if="formOffice"
          placeholder="Sede"
          :valueInput="isEditOffice ? office.officeName : ''"
          :submit="isEditOffice ? 'Actualizar' : 'Crear'"
          @onSubmit="onSubmitOffice"
          @onCancel="onCancelNewOffice"
        ></input-new>
        <label class="location-form__label">Habitación</label>
        <div class="location-form__room">
          <div class="location-form__input-subcontainer">
            <select-input class="location-form__select" :options="getRoomOptions" @selected="roomSelected" ref="select_room"></select-input>
            <button class="location-form__button-add" disabled ref="new_room" @click.prevent="onNewRoom">
              <i class="fas fa-plus"></i>
            </button>
            <button class="location-form__button-edit" disabled ref="edit_room" @click.prevent="onEditRoom">
              <i class="fas fa-pencil-alt" />
            </button>
          </div>
          <div v-if="!!room" :key="resetRoom">
            <ToggleInput class="location-form__toogle-room" :checked="toogleRoom" :label="toogleRoom ? 'Habilitada' : 'Deshabilitada'" @isChecked="onIsCheckedRoom" />
            <ToggleInput class="location-form__toogle-city" :checked="toogleRoomAvailable" :label="toogleRoomAvailable ? 'En operacion' : 'Retirada'" @isChecked="onIsCheckedAvailabilityRoom" />
          </div>
          <StarsRatingsInput class="location-form__stars" v-show="isQualification" ref="qualification" :qualification="qualificationRoom" />
        </div>
        <input-new
          v-if="formRoom"
          placeholder="Habitación"
          :valueInput="isEditRoom ? room.roomName : ''"
          :submit="isEditRoom ? 'Actualizar' : 'Crear'"
          :isQualification="true"
          :qualification="isEditRoom ? qualificationRoom : 0"
          :ip="ip"
          @onSubmit="onSubmitRoom"
          @onCancel="onCancelNewRoom"
        ></input-new>
      </div>
    </form>
  </section>
</template>
<script>
  import axios from "@/api/axios";
  import dataList from "@/components/widgets/DataList.vue";
  import inputNew from "@/components/widgets/InputNew.vue";
  import selectInput from "@/components/widgets/SelectInput.vue";
  import { mapActions, mapGetters } from "vuex";
  import Swal from "sweetalert2";
  import StarsRatingsInput from "@/components/input/StarsRatingsInput.vue";
  import ToggleInput from "@/components/input/ToggleInput.vue";

  export default {
    components: { selectInput, inputNew, dataList, StarsRatingsInput, ToggleInput },
    data: function() {
      return {
        formCity: false,
        formOffice: false,
        formRoom: false,
        isEditCity: false,
        isEditOffice: false,
        isEditRoom: false,
        qualification: null,
        isQualification: false,
        default: { value: "", text: "Seleccione..." },
        city: null,
        office: null,
        room: null,
        toogleCity: null,
        toogleOffice: null,
        toogleRoom: null,
        toogleCityAvailable: null,
        toogleOfficeAvailable: null,
        toogleRoomAvailable: null,
        ip: { value: "", placeholder: "Ingresar ip..." },
        resetRoom: 1,
        resetOffice: 20,
        resetCity: 30,
      };
    },
    computed: {
      rooms() {
        return this.$store.state.rooms.data;
      },
      offices() {
        return this.$store.state.offices.data;
      },
      cities() {
        return this.$store.state.cities.data;
      },
      //Cargar el sede ciudad
      getCityOptions: function() {
        let _cities = this.cities.map((city) => {
          return { value: city.cityName, text: city.cityName };
        });
        _cities.unshift(this.default);
        return _cities;
      },
      //Carga el select sede
      getOfficeOptions: function() {
        const cityFound = !!this.city ? this.cities.find((city) => city.cityName === this.city.cityName) : null;
        if (!!cityFound ? cityFound.isEnable : false) {
          let offices = this.offices
            .filter((office) => office.cityName === this.city.cityName)
            .map((office) => {
              return { value: office.officeName, text: office.officeName };
            });
          offices.unshift(this.default);
          return offices;
        } else {
          return [this.default];
        }
      },
      //Carga el select habitación
      getRoomOptions: function() {
        const officeFound = !!this.office ? this.offices.find((office) => office.officeName === this.office.officeName) : null;
        if (!!officeFound ? officeFound.isEnable : false) {
          let rooms = this.rooms
            .filter((room) => room.officeName === this.office.officeName)
            .map((room) => {
              return { value: room.roomName, text: room.roomName };
            });
          rooms.unshift(this.default);
          return rooms;
        } else {
          return [this.default];
        }
      },
      qualificationRoom: function() {
        if (!!this.room) {
          const _room = this.rooms.find((room) => room.roomName === this.room.roomName);
          return !!_room ? _room.qualification : 0;
        }
        return 0;
      },
    },
    methods: {
      ...mapActions("cities", { fetchCities: "fetch" }),
      ...mapActions("offices", { fetchOffices: "fetch" }),
      ...mapActions("rooms", { fetchRooms: "fetch" }),

      //Eventos ciudad
      citySelected: async function(value) {
        this.formCity = false;
        this.formOffice = false;
        this.formRoom = false;
        this.$refs.select_office.$el.removeAttribute("disabled");
        this.$refs.select_room.$el.removeAttribute("disabled");
        if (value !== "") {
          this.city = this._getCity(value) || null;
          this.toogleCity = this.city.isEnable;
          this.toogleCityAvailable = this.city?.cityStatus !== "retired";
        } else {
          this.city = null;
          this.$refs.new_city.removeAttribute("disabled");
        }
        this.$refs.select_office.reboot();
        this.$refs.select_room.reboot();
        this.office = null;
        this.room = null;
        this.qualification = null;
        this.isQualification = false;
        this._onChangeCity();
        this._onChangeOffice();
        this._onChangeRoom();
      },
      onSubmitCity: async function(city) {
        this.dataSending();
        let res;
        let id = "";
        if (this.isEditCity) {
          id = this.cities.find((city) => city.cityName === this.city.cityName)._id;
          res = await this.update("cities", id, { cityName: city });
        } else {
          res = await this.create("cities", { cityName: city });
        }
        Swal.close();
        if (!res.error) {
          await Swal.fire({
            text: this.isEditCity ? "Ciudad actualizada con éxito" : "Ciudad creada con éxito",
            icon: "success",
            confirmButtonColor: "#bd0909",
          });
          this.dataSending();
          await this.fetch("city");
          await this.fetch("office");
          Swal.close();
          this.city = this._getCity(city) || null;
          this.toogleCity = !!this.city ? this.city.isEnable : true;
          this.isEditCity = false;
          this.$refs.new_city.removeAttribute("disabled");
          this.$refs.select_city.$el.removeAttribute("disabled");
          if (this.city) this.$refs.edit_city.removeAttribute("disabled");
          else this.$refs.edit_city.setAttribute("disabled", "");
          this.$refs.select_city.selectedOption(this.getCityOptions.findIndex((item) => item.value === city));
          this.formCity = false;
          this._onChangeCity();
        } else {
          Swal.fire({
            text: res.error,
            icon: "error",
            confirmButtonColor: "#bd0909",
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.city = null;
              this.office = null;
              this.room = null;
              this.isQualification = false;
              this.qualification = null;
              this.formCity = false;
              this.isEditCity = false;
              this.$refs.edit_city.setAttribute("disabled", "");
              this.$refs.new_city.removeAttribute("disabled");
              this.$refs.select_city.$el.removeAttribute("disabled");
              this.$refs.select_office.reboot();
              this.$refs.select_room.reboot();
              this._onChangeCity();
              this._onChangeOffice();
            }
          });
        }
      },
      onNewCity: function() {
        this.formCity = !this.formCity;
        if (this.formCity) {
          this.$refs.edit_city.setAttribute("disabled", "");
          this.$refs.select_city.$el.setAttribute("disabled", "");
        } else if (this.city) {
          this.$refs.edit_city.removeAttribute("disabled");
          this.$refs.select_city.$el.removeAttribute("disabled");
        } else this.$refs.select_city.$el.removeAttribute("disabled");
      },
      onEditCity: function() {
        this.formCity = !this.formCity;
        this.isEditCity = !this.isEditCity;
        if (this.formCity) {
          this.$refs.new_city.setAttribute("disabled", "");
          this.$refs.select_city.$el.setAttribute("disabled", "");
        } else {
          this.$refs.new_city.removeAttribute("disabled");
          this.$refs.select_city.$el.removeAttribute("disabled");
        }
      },
      onCancelNewCity: function() {
        this.formCity = false;
        this.isEditCity = false;
        this.$refs.new_city.removeAttribute("disabled");
        this.$refs.select_city.$el.removeAttribute("disabled");
        if (this.city) this.$refs.edit_city.removeAttribute("disabled");
        else this.$refs.edit_city.setAttribute("disabled", "");
      },
      onIsCheckedCity: async function(value) {
        if (value && !this.toogleCityAvailable) {
          this.toogleCity = false;
          this.resetCity++;
          return;
        }
        this.dataSending();
        this.toogleCity = value;
        const { data } = await axios.patch(`/cities/enables/${this.city._id}`, { isEnable: value });
        if (data._id) {
          this.$store.commit("cities/update", data);
          this.city = data;
        }
        if (!value) {
          await this.fetch("office");
          await this.fetch("room");
        }
        this._onChangeCity();
        Swal.close();
      },
      onIsCheckedAvailabilityCity: async function(value) {
        if (!value && this.toogleCity) {
          this.toogleCity = true;
          this.resetCity++;
          return;
        }
        this.dataSending();
        this.toogleCityAvailable = value;
        const { data } = await axios.patch(`/cities/enables/${this.city._id}`, { isEnable: false, cityStatus: value ? "available" : "retired" });
        if (data._id) {
          this.$store.commit("cities/update", data);
          this.city = data;
        }
        await this.fetch("office");
        await this.fetch("room");
        this._onChangeCity();
        Swal.close();
      },

      //Event Select sede
      officeSelected: async function(office) {
        this.formOffice = false;
        this.formRoom = false;
        this.$refs.select_room.$el.removeAttribute("disabled");
        this.room = null;
        this.isQualification = false;
        if (office !== "") {
          this.office = this._getOffice(office) || null;
          this.toogleOffice = this.office.isEnable;
          this.toogleOfficeAvailable = this.office?.officeStatus !== "retired";
        } else {
          this.office = null;
          this.$refs.new_office.removeAttribute("disabled");
        }
        this.$refs.select_room.reboot();
        this._onChangeOffice();
        this._onChangeRoom();
      },
      onSubmitOffice: async function(office) {
        this.dataSending();
        let res;
        if (this.isEditOffice) {
          const id = this.offices.find((office) => office.officeName === this.office.officeName)._id;
          res = await this.update("offices", id, { officeName: office });
        } else {
          res = await this.create("offices", {
            cityName: this.city.cityName,
            cityId: this.city._id,
            officeName: office,
          });
        }
        Swal.close();
        if (!res.error) {
          await Swal.fire({
            text: this.isEditOffice ? "Sede actualizada con éxito" : "Sede creada con éxito",
            icon: "success",
            confirmButtonColor: "#bd0909",
          });
          this.dataSending();
          await this.fetch("office");
          await this.fetch("room");
          Swal.close();
          this.office = this._getOffice(office) || null;
          this.toogleOffice = !!this.office ? this.office.isEnable : true;
          this.isEditOffice = false;
          this.$refs.new_office.removeAttribute("disabled");
          this.$refs.select_office.$el.removeAttribute("disabled");
          if (this.office) this.$refs.edit_office.removeAttribute("disabled");
          else this.$refs.edit_office.setAttribute("disabled", "");
          this.$refs.select_office.selectedOption(this.getOfficeOptions.findIndex((item) => item.value === office));
          this.formOffice = false;
          this._onChangeOffice();
        } else {
          Swal.fire({
            text: res.error,
            icon: "error",
            confirmButtonColor: "#bd0909",
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.office = null;
              this.room = null;
              this.qualification = null;
              this.isQualification = false;
              this.isEditOffice = false;
              this.$refs.new_office.removeAttribute("disabled");
              this.$refs.select_office.$el.removeAttribute("disabled");
              this.$refs.edit_office.setAttribute("disabled", "");
              this.$refs.select_room.reboot();
              this._onChangeOffice();
              this.formOffice = false;
            }
          });
        }
      },
      onNewOffice: function() {
        this.formOffice = !this.formOffice;
        if (this.formOffice) {
          this.$refs.edit_office.setAttribute("disabled", "");
          this.$refs.select_office.$el.setAttribute("disabled", "");
        } else if (this.office) {
          this.$refs.edit_office.removeAttribute("disabled");
          this.$refs.select_office.$el.removeAttribute("disabled");
        } else this.$refs.select_office.$el.removeAttribute("disabled");
      },
      onEditOffice: function() {
        this.formOffice = !this.formOffice;
        this.isEditOffice = !this.isEditOffice;
        if (this.formOffice) {
          this.$refs.new_office.setAttribute("disabled", "");
          this.$refs.select_office.$el.setAttribute("disabled", "");
        } else {
          this.$refs.new_office.removeAttribute("disabled");
          this.$refs.select_office.$el.removeAttribute("disabled");
        }
      },
      onCancelNewOffice: function() {
        this.formOffice = false;
        this.isEditOffice = false;
        this.$refs.new_office.removeAttribute("disabled");
        this.$refs.select_office.$el.removeAttribute("disabled");
        if (this.office) this.$refs.edit_office.removeAttribute("disabled");
        else this.$refs.edit_office.setAttribute("disabled", "");
      },
      onIsCheckedOffice: async function(value) {
        if (value && !this.toogleOfficeAvailable) {
          this.toogleOffice = false;
          this.resetOffice++;
          return;
        }
        this.dataSending();
        this.toogleOffice = value;
        const { data } = await axios.patch(`/offices/enables/${this.office._id}`, { isEnable: value });
        if (data._id) {
          this.$store.commit("offices/update", data);
          this.office = data;
        }
        await this.fetch("room");
        Swal.close();
      },
      onIsCheckedAvailabilityOffice: async function(value) {
        if (!value && this.toogleOffice) {
          this.toogleOfficeAvailable = true;
          this.resetOffice++;
          return;
        }
        this.dataSending();
        this.toogleOfficeAvailable = value;
        const { data } = await axios.patch(`/offices/enables/${this.office._id}`, { isEnable: false, officeStatus: value ? "available" : "retired" });
        if (data._id) {
          this.$store.commit("offices/update", data);
          this.office = data;
        }
        await this.fetch("room");
        Swal.close();
      },

      //Eventos habitacion
      roomSelected: async function(room) {
        this.formRoom = false;
        if (room !== "") {
          this.room = this._getRoom(room) || null;
          this.ip.value = this.room?.ip || "";
          this.qualification = this.room?.qualification || null;
          this.isQualification = true;
          this.toogleRoom = this.room?.isEnable;
          this.toogleRoomAvailable = this.room?.roomStatus !== "retired";
        } else {
          this.room = null;
          this.toogleRoomAvailable = null;
          this.isQualification = false;
          this.ip.value = "";
          this.$refs.new_room.removeAttribute("disabled");
        }
        this._onChangeRoom();
      },
      onSubmitRoom: async function(room, qualifi, ip) {
        this.dataSending();
        let res;
        if (this.isEditRoom) {
          const id = this.rooms.find((room) => room.roomName === this.room.roomName)._id;
          res = await this.update("rooms", id, {
            roomName: room,
            qualification: qualifi,
            ip,
          });
        } else {
          res = await this.create("rooms", {
            officeName: this.office.officeName,
            officeId: this.office._id,
            roomName: room,
            qualification: qualifi,
            ip,
          });
        }
        Swal.close();
        if (!res.error) {
          Swal.fire({
            text: this.isEditRoom ? "Habitación actualizada con éxito" : "Habitación creada con éxito",
            icon: "success",
            confirmButtonColor: "#bd0909",
          }).then(async (result) => {
            await this.fetch("room");
            this.room = this._getRoom(room);
            this.toogleRoom = !!this.room ? this.room.isEnable : true;
            this.isEditRoom = false;
            this.$refs.new_room.removeAttribute("disabled");
            this.$refs.select_room.$el.removeAttribute("disabled");
            if (this.room) this.$refs.edit_room.removeAttribute("disabled");
            else this.$refs.edit_room.setAttribute("disabled", "");
            this.$refs.select_room.selectedOption(this.getRoomOptions.findIndex((item) => item.value === room));
            this.formRoom = false;
            this.isQualification = true;
          });
        } else {
          Swal.fire({
            text: res.error,
            icon: "error",
            confirmButtonColor: "#bd0909",
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.formRoom = false;
              this.room = null;
              this.qualification = null;
              this.isQualification = false;
              this.isEditRoom = false;
              this.$refs.new_room.removeAttribute("disabled");
              this.$refs.select_room.$el.removeAttribute("disabled");
              this.$refs.edit_room.setAttribute("disabled", "");
            }
          });
        }
      },
      onNewRoom: function() {
        this.formRoom = !this.formRoom;
        this.formRoom ? (this.isQualification = false) : (this.isQualification = true);
        if (this.formRoom) {
          this.$refs.edit_room.setAttribute("disabled", "");
          this.$refs.select_room.$el.setAttribute("disabled", "");
        } else if (this.room) {
          this.$refs.edit_room.removeAttribute("disabled");
          this.$refs.select_room.$el.removeAttribute("disabled");
        } else this.$refs.select_room.$el.removeAttribute("disabled");
      },
      onEditRoom: function() {
        this.formRoom = !this.formRoom;
        this.isEditRoom = !this.isEditRoom;
        this.formRoom ? (this.isQualification = false) : (this.isQualification = true);
        if (this.formRoom) {
          this.$refs.new_room.setAttribute("disabled", "");
          this.$refs.select_room.$el.setAttribute("disabled", "");
        } else {
          this.$refs.new_room.removeAttribute("disabled");
          this.$refs.select_room.$el.removeAttribute("disabled");
        }
      },
      onCancelNewRoom: function() {
        this.formRoom = false;
        this.isEditRoom = false;
        this.room ? (this.isQualification = true) : (this.isQualification = false);
        this.$refs.new_room.removeAttribute("disabled");
        this.$refs.select_room.$el.removeAttribute("disabled");
        if (this.room) this.$refs.edit_room.removeAttribute("disabled");
        else this.$refs.edit_room.setAttribute("disabled", "");
      },
      onIsCheckedRoom: async function(value) {
        if (value && !this.toogleRoomAvailable) {
          this.toogleRoom = false;
          this.resetRoom++;
          return;
        }
        this.dataSending();
        this.toogleRoom = value;
        const { data } = await axios.patch(`rooms/enables/${this.room._id}`, { isEnable: value });
        if (data._id) {
          this.$store.commit("rooms/update", data);
          this.room = data;
        }
        Swal.close();
      },
      onIsCheckedAvailabilityRoom: async function(value) {
        if (!value && this.toogleRoom) {
          this.toogleRoomAvailable = true;
          this.resetRoom++;
          return;
        }
        this.toogleRoomAvailable = value;
        this.dataSending();
        const { data } = await axios.patch(`rooms/enables/${this.room._id}`, { isEnable: false, roomStatus: value ? "available" : "retired" });
        if (data._id) {
          this.$store.commit("rooms/update", data);
          this.room = data;
        }
        Swal.close();
      },
      dataSending: function() {
        Swal.fire({
          title: "Espere por favor...",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
      },
      fetch: async function(location) {
        const locations = {
          city: () => this.fetchCities("?all=true"),
          office: () => {
            this.office = null;
            this.fetchOffices("all");
          },
          room: () => this.fetchRooms("?all=true"),
        };
        this.room = null;
        this.isQualification = false;

        locations?.[location]?.();
      },
      /**
       * Crear con axios
       * @param {String} Nombre del endpoint
       * @param {Object} Datos a guardar
       * return Object
       */
      create: async function(location, payload) {
        const response = await axios.post(`/${location}`, payload);
        if (response.data.error) return { error: response.data.error };
        else return {};
      },
      /**
       * Actualizar con axios
       * @param {String} Nombre del endpoint
       * @param {int} Id
       * @param {Object} Datos a guardar
       * return Object
       */
      update: async function(location, id, payload) {
        const uri = `/${location}/${id}`;
        const response = await axios.patch(uri, payload);
        if (response.data.error) return { error: response.data.error };
        else return {};
      },
      _onChangeCity: function() {
        if (this.city !== null && !this.cities.find((city) => city.cityName === this.city.cityName).isEnable) {
          this.$refs.new_office.setAttribute("disabled", "");
          this.$refs.edit_city.removeAttribute("disabled");
        } else if (this.city !== null) {
          this.$refs.new_office.removeAttribute("disabled");
          this.$refs.edit_city.removeAttribute("disabled");
        } else {
          this.$refs.new_office.setAttribute("disabled", "");
          this.$refs.edit_city.setAttribute("disabled", "");
        }
      },
      _onChangeOffice: function() {
        if (this.office !== null && !this.offices.find((office) => office.officeName === this.office.officeName).isEnable) {
          this.$refs.new_room.setAttribute("disabled", "");
          this.$refs.edit_office.removeAttribute("disabled");
        } else if (this.office !== null) {
          this.$refs.new_room.removeAttribute("disabled");
          this.$refs.edit_office.removeAttribute("disabled");
        } else {
          this.$refs.new_room.setAttribute("disabled", "");
          this.$refs.edit_office.setAttribute("disabled", "");
        }
      },
      _onChangeRoom: function() {
        if (this.room !== null) {
          this.$refs.edit_room.removeAttribute("disabled");
        } else {
          this.$refs.edit_room.setAttribute("disabled", "");
        }
      },
      _getCity: function(city) {
        return this.cities.find((_city) => _city.cityName === city);
      },
      _getOffice: function(office) {
        return this.offices.find((_office) => _office.officeName === office);
      },
      _getRoom: function(room) {
        return this.rooms.find((_room) => _room.roomName === room);
      },
    },
    mounted: async function() {
      await this.fetch("city");
      await this.fetch("office");
      await this.fetch("room");
      this.officesOptions = [this.default];
    },
    async destroyed() {
      await this.fetchRooms();
      await this.fetchOffices();
      await this.fetchCities();
    },
  };
</script>

<style lang="scss">
  .swal2-loader {
    border-color: $lipstick transparent $lipstick;
  }
  .location-form {
    width: 100%;
    box-shadow: $dshadow;
    border-radius: $mradius;
    background-color: $white;
    margin-top: 50px;
    @include Column();
    &__title {
      margin: 10px;
    }
    &__form {
      width: 100%;
      padding: $mpadding;
    }
    &__input-container {
      @include Column(flex-start, flex-start);
      width: 100%;
    }
    &__label {
      margin-top: 5px;
      padding: 5px;
      font-weight: bold;
      font-size: 14px;
      color: $gray-emperor;
    }
    &__input-subcontainer {
      @include Row(flex-start, flex-start);
      width: 100%;
    }
    &__city,
    &__office,
    &__room {
      width: 100%;
    }
    &__toogle {
      margin-left: 5px;
      &:hover {
        cursor: auto;
      }
      &-city,
      &-office,
      &-room {
        margin: 5px 0 0 5px;
      }
    }
    &__stars {
      flex-direction: column;
      position: relative;
      &:after {
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        top: 5px;
        z-index: 10;
      }
      .stars-component {
        &-title {
          margin-right: 15px;
          padding-left: 5px;
          font-size: 14px;
        }
      }
      .rating {
        margin-left: 5px;
      }
      .rating:not(:hover) label input:checked ~ .icon {
        color: $lipstick;
      }
    }
    &__new {
      padding: $mpadding;
      box-shadow: $dshadow;
      border-radius: $mradius;
    }
    &__select {
      width: 100%;
      margin-bottom: 5px;
    }
    &__data-list {
      @include Row();
      width: 100%;
      height: 33px;
      margin: auto;
    }
    &__input {
      width: 100%;
      height: 33px;
      margin: auto;
      @include Input();
      &[placeholder] {
        color: $chicago;
      }
    }
    &__input:focus {
      box-shadow: 1px 1px 5px $gray-emperor;
    }
    &__button-add {
      @include ButtonStyle();
      background-color: $primary-color;
      width: 33px;
      height: 33px;
      margin-left: 10px;
      padding: 5px;
      transition: 500ms;
      &[disabled] {
        @include ButtonRedDisabled();
      }
    }
    &__button-edit {
      @extend .location-form__button-add;
    }
    &__button {
      @include ButtonRed();
      margin: 5px auto;
      outline: none;
      border: none;
      &:hover {
        @include ButtonRedActive();
        outline: none;
      }
      &:active {
        @include ButtonRed();
      }
      &[disabled] {
        @include ButtonRedDisabled();
      }
    }
    @media screen and (min-width: $desktop_width) {
      &__label {
        font-size: 16px;
      }
      &__select,
      &__input {
        height: 38px;
      }
      &__button-add {
        width: 38px;
        height: 38px;
      }
    }
  }
</style>
